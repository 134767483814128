import { get, patch, post, destroy } from '../helpers'

const BASE_URL = 'api/junglekit/amazon_seller_accounts'

export default {
  getAmazonSellerAccounts() {
    return get(`api/junglekit/amazon_seller_accounts`)
  },

  createSellerAccount(account, location) {
    const data = {
      ...account,
      location
    }

    // setting a big timeout value as a temp measure
    // it is very important to wait on this request to finish
    return post(BASE_URL, { data, timeout: 120000 })
  },

  getAmazonMarketplacesList() {
    return get('api/junglekit/marketplaces')
  },

  getAmazonSellerAccountStatus(sellerID) {
    return get(`api/junglekit/sellers/${sellerID}/status`)
  },

  patchAmazonSellerAccountDetails({
    sc_email,
    label,
    id,
    default_marketplace_id
  }) {
    return patch(`${BASE_URL}/${id}`, {
      data: {
        sc_email,
        label,
        default_marketplace_id
      }
    })
  },

  deleteAmazonSellerAccount(accountId) {
    return destroy(`${BASE_URL}/${accountId}`)
  },

  patchDismissStatusBar(sellerID, sellerAccountId) {
    return patch(
      `api/junglekit/sellers/${sellerID}/disable_sync_statuses?amazon_seller_account_id=${sellerAccountId}`
    )
  }
}
